<template>
  <div>
    <loading-circles v-if="loading"></loading-circles>
    <div class="table-responsive" v-if="data !== null">
      <div class="d-flex m-3">
        <button @click="deleteSelected" class="btn-link">{{ translate('Remove selected', 'entities') }}</button>
      </div>

      <table class="table align-items-center" v-if="editableColumnsToDisplay">
        <thead>
          <tr>
            <th width="10px">
              <div class="custom-control custom-checkbox">
                <input id="select-all" type="checkbox" class="custom-control-input" @click="selectAll" />
                <label class="custom-control-label" for="select-all"></label>
              </div>
            </th>
            <th scope="col" v-for="(column, ci) in editableColumnsToDisplay" :key="ci">
              {{ translate(entityLabel(column.label, true), 'entities') }}
            </th>
            <th scope="col" class="text-right bg-white">
              {{ translate('Actions', 'global') }}
            </th>
          </tr>
        </thead>
        <tbody class="list">
          <tr v-for="(row, i) in data" :key="i">
            <td width="10px">
              <div class="custom-control custom-checkbox">
                <input :id="'row-' + i" type="checkbox" class="custom-control-input"
                       v-model="data[i].selected">
                <label class="custom-control-label" :for="'row-' + i" />
              </div>
            </td>
            <td v-for="(column, ti) in editableColumnsToDisplay" :key="ti" class="w-auto">
              <component :is="getComponentName(column.type)"
                         v-model="row[column.name]"
                         :field-props="column"
                         @input="row.modified = true; $emit('input', dataFormatted)"
              />
            </td>
            <td class="text-right bg-white" v-if="canAll(['access', 'delete'], entitySlug)">
              <action-button v-tooltip="translate('Delete', 'entities')"
                             class-icon="fa-trash-alt" @click.native="deleteRow(i)">
              </action-button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="d-flex justify-content-end m-3">
        <button class="btn btn-secondary" @click="addRow">{{ translate('Add new row', 'entities') }}</button>
      </div>

    </div>
  </div>
</template>

<script>
import EntityIndexMixin from "@/mixins/EntityIndexMixin"
import { canAll, getEntityName } from "@/utilities/helper"
import LoadingCircles from "./../../../components/utilities/LoadingCircles.vue"
import { getComponentName } from "@/modules/erp_entities/utilities/helper"
import { ColorPickerField, InputField, RadioField, RangeField, SelectField, TextareaField, TextareaSimpleField, TreeselectField } from "@/modules/erp_entities/components/page/form/fields"

export default {
  name: "DataTableEditable",
  components: {
    LoadingCircles, ColorPickerField, InputField, RadioField, RangeField, SelectField, TextareaField, TextareaSimpleField, TreeselectField,
  },
  mixins: [EntityIndexMixin],
  props: {
    entityType: {
      required: true,
    },
    dtFilters: {
      required: false,
    },
    relationField: {
      type: String,
      required: true,
    },
    fieldProps: {
      required: true,
    },
    dataFilters: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      data: null,
      dataToDelete: [],
    }
  },
  computed: {
    dataFormatted() {
      return {
        dataToCreate: this.data.filter(item => !item.id).map(({ modified, selected, ...keepAttrs }) => keepAttrs), // remove "modified" and "select"
        dataToUpdate: this.data.filter(item => item.id && item.modified).map(({ modified, selected, ...keepAttrs }) => keepAttrs), // remove "modified" and "select"
        dataToDelete: this.dataToDelete,
        relationField: this.relationField,
      }
    },
    editableFieldTypes() {
      return ['color_picker', 'int', 'multiselect', 'number', 'radio', 'range', 'select', 'simple_textarea', 'text', 'textarea', 'treeselect']
    },
    editableColumns() {
      return this.records.columns
        .filter(col => this.editableFieldTypes.includes(col.type) && parseInt(col.visible_in_table) === 1 && col.permission !== 'd') || []
    },
    editableColumnsToDisplay() {
      return this.editableColumns
        .filter(col => col.name !== this.relationField)
    },
    entityName(){
      return getEntityName(this.entitySlug)
    },
    entitySlug() {
      return this.records.entity_slug
    },
  },
  methods: {
    canAll,
    getComponentName,
    addRow() {
      const newItem = {}
      this.editableColumns.forEach(column => {
        newItem[column.name] = ''
      })
      newItem[this.relationField] = this.$route.params.id || 0
      this.data.push(newItem)
      this.$emit('input', this.dataFormatted)
    },
    deleteSelected() {
      this.data.forEach((row, index) => {
        if(row.selected && row.id) {
          this.dataToDelete.push(row.id)
        }
      })

      this.data = this.data.filter(item => !item.selected)
      this.$emit('input', this.dataFormatted)
    },
    selectAll() {
      if (this.data) {
        this.data.forEach(item => {
          this.$set(item, 'selected', !item.selected)
        })
      }
    },

    async deleteRow(rowI) {
      if(this.data[rowI].id){
        this.dataToDelete.push(this.data[rowI].id)
      }
      this.data.splice(rowI, 1)
      this.$emit('input', this.dataFormatted)
    },

    watchFields(fieldsToWatch) {
      fieldsToWatch.forEach(el => {
        this.$watch(
          `dataFilters.${el}.value`, val => {
            console.log('hello')
            this.loadDataDebounced()
          },
        )
      })
    },

  },
  async created() {
    this.useUserPreferences = false

    if(this.dtFilters){
      this.userPreferences.filters = this.dtFilters
    }

    if (this.fieldProps.data_filters && this.fieldProps.data_filters.length) {
      this.watchFields(this.fieldProps.data_filters)
    }

    this.modifyData = false
    await this.loadData()

    this.data = this.records.data.map(item => {
      return {
        id: item.id,
        modified: false,
        ...this.editableColumns.reduce((acc, column) => {
          acc[column.name] = item[column.name]
          return acc
        }, {}),
      }
    })

  },
}
</script>
